import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import useStyles from "../style";

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <header className={classes.header}>
          <div className={classes.logo}>
            <a rel="noopener noreferrer" href="https://thegamers.com.br/">
              <LogoIcon />
            </a>
          </div>

          <div className={classes.headerButtons}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://facebook.com/thegamers.com.br/"
            >
              <img
                width={120}
                src={require("../assets/facebook.png")}
                alt="facebook"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/thegamers.com.br/"
            >
              <img
                width={120}
                src={require("../assets/instagram.png")}
                alt="instagram"
              />
            </a> 
          </div>
        </header>

        <div className={classes.mediasContent}>
          <div className={classes.medias}></div>
        </div>

        <div>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <div className={classes.titleContent}>
                <Typography component="h1" className={classes.title}>
                  Comunicado Importante
                </Typography>
              </div>
              <Typography className={classes.subtitle}>
                Prezados usuários,
              </Typography>
              <Typography className={classes.subtitle}>
                Informamos que as atividades do nosso site estarão{" "}
                <strong>temporariamente pausadas</strong> a partir de{" "}
                <strong>1 de Outubro de 2024</strong>. Durante esse
                período, estaremos focados na reestruturação completa da rede.
              </Typography>
              <Typography className={classes.subtitle}>
                Agradecemos a compreensão de todos e estamos trabalhando para
                que em breve possamos voltar com novidades e melhorias para
                oferecer a melhor experiência possível.
              </Typography>
              <Typography className={classes.subtitle}>
                Fiquem atentos às nossas redes sociais e canais de comunicação
                para futuras atualizações.
              </Typography>
              <Typography className={classes.subtitle}>
                Atenciosamente, <br />
                <strong>Equipe The Gamers</strong>
              </Typography>
              <div className={classes.form}>
                <Button
                  size="large"
                  variant="contained"
                  className={classes.button}
                  component="a"
                  href="https://thegamers.com.br/"
                  disableElevation
                >
                  Voltar para Home
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Home;
