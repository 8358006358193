import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    minHeight: "calc(100vh - 40px)",
    padding: 20,
    background: "linear-gradient(#13151A 0px, #1B1D25 354px)",
    backgroundColor: "#1B1D25",
  },
  content: {
    maxWidth: 1100,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "60px 0",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
      flexDirection: "column",
    },
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      display: "inline-flex",
      marginLeft: theme.spacing(1),
    },
  },
  loginButton: {
    border: "1px solid #16B27A",
    color: "#ffffff",
    borderRadius: 40,
    textTransform: "none",
    padding: "3px 20px",
  },
  mediasContent: {
    height: 180,
    marginBottom: 60,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  medias: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: 180,
    backgroundImage: `url("${require("./assets/media.png")}")`,
    backgroundRepeat: "repeat space",
    backgroundPosition: "-240px 0",
    backgroundSize: "auto 180px",
    overflow: "auto",
  },
  image: {
    maxWidth: "100%",
    width: "100%",
  },
  titleContent: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 36,
    lineHeight: "120%",
    color: "#FFFFFF",
    letterSpacing: "0.05em",
    marginBottom: 16,
    maxWidth: 620,
    "& span": {
      color: "#16B27A",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
      textAlign: "center",
      maxWidth: 325,
    },
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 14,
    color: "#FFFFFF",
    lineHeight: "140%",
    letterSpacing: "0.03em",
    marginBottom: 40,
    maxWidth: 540,
    "& span": {
      color: "#16B27A",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  icon: {
    marginRight: 16,
  },
  text: {
    color: "#FFFFFF",
    fontSize: 14,
    marginBottom: 8,
    letterSpacing: "0.03em",
  },
  form: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 8,
    "& > *": {
      display: "inline-flex",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      flexDirection: "column",
    },
  },
  message: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  input: {
    background: "#242730",
    border: "1px solid #2B2F3A",
    boxSizing: "border-box",
    borderRadius: "3px 0 0 3px",
    padding: "6px 0 6px 20px",
    color: "#FFFFFF",
    width: "100%",
    maxWidth: 300,
  },
  button: {
    backgroundColor: "#16B27A",
    color: "#FFFFFF",
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 5,
    },
    textTransform: "none",
    height: 44,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
    "&:hover": {
      backgroundColor: "#118f62",
    },
  },
  btGoogle: {
    marginRight: theme.spacing(2),
  },
  storeSection: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      marginTop: theme.spacing(2)
    },
  },
}));
